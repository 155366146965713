<div>
  <div mat-dialog-title class="modal-title">{{data.title}}
    <div class="pull-right">
      <mat-icon class="close-btn" style="cursor: pointer;" (click)="closeModel()">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="payment-radio__container tw-px-2.5">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="data.eGroup" (change)="radioChange($event)">

    <!--<mat-radio-button value="achCard" [disabled] = "tsysAch"-->
    <mat-radio-button value="achCard"
                      #tooltip="matTooltip" matTooltipPosition="above"
                      matTooltip="{{'ACH'}}">ACH </mat-radio-button>
                      <!--matTooltip="{{tsysAch ? 'Please setup ACH details for proceeding ACH payment.!' : 'ACH'}}">ACH </mat-radio-button>-->

    <!--<mat-radio-button value="creditCard" [disabled] = "tsysCC"-->
      <mat-radio-button value="creditCard"
        #tooltip="matTooltip" matTooltipPosition="above"
        matTooltip="{{'CreditCard'}}">CreditCard
        <!--matTooltip="{{tsysCC ? 'Please setup Credit Cards details for proceeding CC payment.!' : 'CreditCard'}}">CreditCard-->
    </mat-radio-button>

  </mat-radio-group>
</div>
  <div mat-dialog-content class="tw-p-0" *ngIf="data.eGroup === 'creditCard'"> 
      <div class="detail-view showfileds">
            <html class="html-tsys">	
  	
              <head>	
              </head>	
  	
              <body style="background:#ffffff;">	
                <form method="POST" id="payment-form" class="tw-flex tw-flex-wrap">	
                  <div class="tw-basis-full tw-px-1.5">	
                    <div class="label-field">Credit Card Number</div>	
                    <div id="tsep-cardNumDiv" class="tsys-div"></div>	
                  </div>	
                  <div class="tw-basis-1/3 tw-px-1.5">	
                    <div class="label-field">Zip Code</div>	
                    <div id="tsep-zipcode" class="form-group mb tsys-div">	
                      <input #textInput type="text" class="form-control" id="zipcode" name="zipcode" placeholder="00000"	
                        (input)="SearchData(textInput.value)">	
                    </div>	
                  </div>	
                  <div class="tw-basis-1/3 tw-px-1.5">	
                    <div class="label-field">Exp Date</div>	
                    <div id="tsep-datepickerDiv" class="tsys-div"></div>	
                  </div>	
                  <div class="tw-basis-1/3 tw-px-1.5">	
                    <div class="label-field">CVV</div>	
                    <div id="tsep-cvv2Div" class="tsys-div"></div>	
                  </div>	
                  <div id="div_console"></div>	
                </form>	
              </body>	
            </html>
          <div class="pay-now">

          </div>
        
      </div>
     <span *ngIf="showErrorMsg" class="errorMsg">CVC verification failed</span>
      <div class="mat-pay-Class">
        <div class="ach-pay-btn">
          <input type="checkbox" class="check tw-w-4 tw-h-4 tw-float-left tw-mt-1 tw-mr-2" [ngModel]="checked" (ngModelChange)="OnChange($event)">
          <div class="tw-inline">
            <span class="mandatory-field tw-pr-1">*</span>
            <span class="overflow-auto tw-text-[12px] leading-none tw-font-semibold">Customer acknowledges receipts of goods and/or services in the amount of the total<wbr> shown hereon and argees to perform the obligations set forth by the customers's <wbr>  agreement with the issuer.</span></div>
        </div>
      </div>
      <div class="tw-text-right tw-mt-4">
        <button class="pay-btn-add-top" mat-raised-button color="primary" (click)="addCCAndActivate()" [disabled]="IsDisabled"
                #tooltip="matTooltip" matTooltipPosition="above"
                matTooltip="{{'Pay'}}"
                class="btn-submit form-button-40"
                type="submit">Pay</button>
      </div>
    </div>


  <div mat-dialog-content class="tw-p-0" *ngIf="data.eGroup === 'achCard'">
      <div class="invoiceDialog">
        <form novalidate [formGroup]="createACHForm" class="md-float-material form-material lead-form tw-p-0" action="javascript:">
          <div class="card-block">
            <div class="form-group form-default form-static-label">
              <ng-select [items]="accountTypeList" appendTo='body' bindLabel="label" bindValue="value" formControlName="accountType" placeholder="Select Account Type *" (change)="updateModule($event)" [ngClass]="typeSelected===false ? 'errorClass' :'noError'">
            </ng-select>
          </div>
        </div>
        <div class="card-block tw-mt-4" *ngIf="accountNameFlag">
          <mat-form-field appearance="outline">
            <mat-label>Business Name</mat-label>
            <input matInput placeholder="Account Holder Name" name="businessName" formControlName="businessName" required>
          </mat-form-field>
        </div>
        <div class="card-bloc tw-mt-4" *ngIf="fnlnFlag">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Account Holder Name" name="fName" formControlName="fName" required>
          </mat-form-field>
          <mat-form-field class="tw-mt-4" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Account Holder Name" name="lName" formControlName="lName" required>
          </mat-form-field>
        </div>

          <!--account type-->
          <div class="card-block tw-mt-2">
            <mat-radio-group formControlName="acctType">
              <mat-radio-button value="checking" >{{'invoices.ach.checking' | translate}}</mat-radio-button>
              <mat-radio-button value="savings" >{{'invoices.ach.savings' | translate}}</mat-radio-button>
            </mat-radio-group>
            <!-- <div class="messages text-danger" *ngIf="(submitted) && (f.acctType.errors?.required)">{{ 'invoices.ach.pleaseSelect' | translate }}</div> -->
          </div>

          <!--routing number-->
          <div class="card-block tw-mt-2">
            <mat-form-field appearance="outline">
              <mat-label >{{'invoices.ach.rtnr' | translate}}</mat-label>
              <input matInput placeholder="{{'invoices.ach.rtnr' | translate}}" name="rtnr" formControlName="rtnr" required>
            </mat-form-field>
          </div>

          <!--account number-->
          <div class="card-block tw-mt-4">
            <mat-form-field appearance="outline">
              <mat-label >{{'invoices.ach.accountNumber' | translate}}</mat-label>
              <input matInput placeholder="{{'invoices.ach.accountNumber' | translate}}" name="accntNum" formControlName="accntNum" required>
            </mat-form-field>
          </div>

          <!--bank name-->
          <div class="card-block tw-mt-4">
            <mat-form-field appearance="outline">
              <mat-label >{{'invoices.ach.bankName' | translate}}</mat-label>
              <input matInput placeholder="{{'invoices.ach.bankName' | translate}}" name="bankName" formControlName="bankName" required>
            </mat-form-field>
          </div>
        </form>
      </div>
     <span *ngIf="showErrorMsg" class="errorMsg">{{'invoices.ach.error' | translate}}</span>
      <div class="tw-text-right tw-mt-4">
        <button mat-raised-button color="primary" (click)="addACHAndActivate()"
                class="btn-submit form-button-40 ach-pay-btn"
                #tooltip="matTooltip" matTooltipPosition="above"
                matTooltip="{{'Pay'}}"
                type="submit">Pay</button>
      </div>
  </div>

</div>
