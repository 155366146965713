import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild, OnDestroy
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertCommonDialogData, IResponse} from '../../../../service/Utils/Interfaces.class';
import { EStatusCode } from '@milagro-ui-core';
import {AppComponentBase} from '../../../shared/AppComponentBase';
import {COMMON, templateType} from '../../../../service/constant';

import {EstimatePopupComponent} from "../estimate-popup/estimate-popup.component";
import {CommonDialogModelComponent} from "../../../shared/common-dialog-model/common-dialog-model.component";
import moment from 'moment';
import { Subscription } from 'rxjs';
import {EstimateService} from "../../../../service/estimate.service";
import {DashboardService} from "../../../../service/dashboard.service";
import { AgreementService } from '../../../../service/agreement.service';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../shared/spinner.component';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass, NgFor, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-estimate-view',
    templateUrl: './estimate-view.component.html',
    styleUrls: ['./estimate-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatLabel, MatTooltip, MatButton, NgFor, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, SpinnerComponent, DecimalPipe, CommonDatePipe, TranslateModule]
})
export class EstimateViewComponent extends AppComponentBase implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  @Output() someEvent = new EventEmitter<string>();
  @ViewChild('invpdf') content: ElementRef;
  @Input() tabGroup: MatTabGroup;

  private estimateId: string;
  public estimateDetList: any;
  public subTotal: string;
  public  tax: string;
  public  discount: string;
  private  paidAmount: string;
  private  estimateDue: string;
  private  estimateTotalAmount: number;
  public  dataSource: string;
  private  cloneUrl: string;
  public error: boolean;
  private encId;
  public showMessage: boolean;
  public representativeSignature: any | string | ArrayBuffer;
  public open: boolean;
  public fullName: string;
  public version: string;
  public themeData: string;
  private path: string;
  public check: boolean;
  public estAcceptedDate: Date;
  public customFields: boolean;
  public fields = [];
  public customElements = [];
  public groupTaxDisplayedColumns: string[];

  private ipAddr: string = '';

  constructor(inject: Injector, private estimateService: EstimateService,private dashboardService: DashboardService,
    private agreementService: AgreementService,
              private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
    super(inject);
    this.version = COMMON.VERSION;
    this.configService.updateIsUserLogged.next(false);
    this.setWindowTitle('Estimates')
  }

  ngOnInit() {
    this.open = false;
    this.showMessage = false;
    this.error = false;
    this.getIPAddress();
    this.estimateId = this.route.snapshot.paramMap.get('id');
    this.getEstimateDetails(this.estimateId);
    this.cloneUrl = location.origin;
    this.showViewMessage();
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  getEstimateDetails(estimateId) {
    const estimateViewDet : Subscription = this.estimateService.estimateViewDetail(estimateId).subscribe((response: IResponse) => {
      if (response.info.code === EStatusCode.OK) {
        this.estimateId = response.data.estimatesId;

        const str = JSON.stringify(response.data);
        this.estimateDetList = JSON.parse(str);
        this.updateItemFormFields();
        this.updateConfigDetailsForClientView(this.estimateDetList.configDetails);

        this.dataSource = this.estimateDetList.items;

        this.paidAmount = this.estimateDetList.paidAmount;
        this.subTotal = this.estimateDetList.subTotal;
        this.tax = this.estimateDetList.tax;
        this.discount = this.estimateDetList.discount;
        this.estimateTotalAmount = this.estimateDetList.amount;
        this.estimateDue = this.estimateDetList.balanceDue;

        this.encId = this.estimateDetList.enc;
        this.fields = this.estimateDetList.customFieldData;
        this.customElements =  this.getCustomFieldValueList(this.fields);
        this.customFields = !!(this.customElements.length);

        if (this.estimateDetList.status === 'PENDING' &&
          Date.parse(this.estimateDetList.dueDate) < Date.parse(moment().format('YYYY-MM-DD'))) {
          this.estimateDetList.status = 'EXPIRED';
          this.showMessage = true;
          this.showViewMessage();
        }
        if (this.estimateTotalAmount < 0) {
          this.estimateTotalAmount = 0.00;
        }
        if (this.estimateDetList.status === 'ACCEPTED' && this.estimateDetList.signature) {
          this.representativeSignature = this.estimateDetList.signature;
          this.fullName = this.estimateDetList.firstName.concat((' ' + this.estimateDetList.lastName) ?
            (' ' + this.estimateDetList.lastName) : (' ' + this.estimateDetList.lastName));
          this.estAcceptedDate = this.estimateDetList.estAcceptedDate ? this.estimateDetList.estAcceptedDate : new Date();
          this.open = true;
        }
      } else {
        this.error = true;
      }
    });
    this.subscriptions.push(estimateViewDet);
  }

  showViewMessage() {
    setTimeout (() => {
      this.showMessage = false;
    }, 3000);
  }
  updateEstimateStatus(status, estimatesId) {
    const dialogRef = this.dialog.open(EstimatePopupComponent, {
      width: '500',
      autoFocus: false,
      disableClose: true,
      panelClass: [
        "animated",
        "slideInDown",
      ],
      data: {title : 'Estimate', eGroup: 'EstimateSignature', firstName: '', lastName: '', open: false}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.eGroup === 'EstimateSignature') {
        this.representativeSignature = result.signature;
        this.fullName = result.firstName.concat(' ' + result.lastName);
        this.cdRef.detectChanges();
        const userId = this.configService.userId ? this.configService.userId : -1;
        if (result.open) {
          const body = {
            estimatesId: estimatesId,
            firstName: result.firstName,
            lastName: result.lastName,
            signature: result.signature
          };
          const acceptSignatureEst : Subscription =  this.estimateService.acceptSignatureEstimate(body).subscribe((response: IResponse) => {
            if (response.info.code === EStatusCode.OK) {
              this.dashboardService.headerNotificationEventListener.next(true);
              const updateEstimateSt : Subscription = this.estimateService.updateEstimateStatus(status, estimatesId, userId, this.ipAddr).subscribe((res: IResponse) => {
                if (res.info.code === EStatusCode.OK) {
                  if (status === 'accepted') {
                    this.showMessage = true;
                    this.estAcceptedDate = new Date();
                    this.estimateDetList.status = 'ACCEPTED';
                    this.showViewMessage();
                    this.open = result.open;
                  }
                }
              });
              this.subscriptions.push(updateEstimateSt);
            }
          });
          this.subscriptions.push(acceptSignatureEst);
        }
      }
    });
  }
  showEstimateDecline(status, estimatesId) {
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Decline',
      message: 'Are you sure you want to decline the estimate?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });
    const userId = this.configService.userId ? this.configService.userId : (this.estimateDetList.clientId ? this.estimateDetList.clientId : this.estimateDetList.leadId);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updateEstimtSt : Subscription = this.estimateService.updateEstimateStatus(status, estimatesId, userId).subscribe((res) => {
          if (res.info.code === EStatusCode.OK) {
            if (status === 'Declined') {
              this.estimateDetList.status = 'DECLINED';
              this.showMessage = true;
              this.showViewMessage();
            }
          }
      });
      this.subscriptions.push(updateEstimtSt);
    }
   });
  }
  downloadPDF(estimateId, name) {
    const getEstimatePdfD : Subscription = this.estimateService.getEstimatePdf(estimateId, name).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        if(res.data){
          this.path = res.data.filename;
          window.open(res.data[0].url);
        }
      } else {
        this.toastr.error(res.data.responseMsg);
      }
    });
    this.subscriptions.push(getEstimatePdfD);
  }
  printDetail(estimateId, name) {
    const filename = 'client_' + name.toLowerCase() + '_estimate_' + estimateId;
    this.check = true;
    const innerContents = document.getElementById('print').innerHTML;
    const popupWinindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><title>'+filename+'</title><style>@page { size: auto;  margin: 5mm; }.page-footer {left: 238px;right: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;}' +
      '.inv-sub-header{padding: 10px;font-weight: bold;padding-left: 20px;color: black;text-align: right;border-bottom: 1px solid #4caf50;margin-top: 60px}.inv-sub-main-content {background-color: #f3f4f6;margin-top: 10px;}.inv-table.mat-table .mat-column-item_desc {width: 20%;padding-right: 20px;}'  +
      '.headerName {    display: inline-block;text-align: center;width: 90%;position: relative;bottom: 25px; text-transform: capitalize;}.inv-sub-detail-content{background-color: white;min-height: 530px;color: black;font-weight: 600;margin:0px -15px;}.light-font{font-weight: lighter;}.tag-btn {font-size: 12px;font-weight: 500;border-radius: 3px;padding: 0px 5px !important;background-color: transparent;text-transform: uppercase;}' +
      '.pull-left {float:left;}.col-md-3 {width: 25%;}.addressAlign{float: left!important;width: 25%;margin-top: 5px;margin-left: 15px}.clearfix{width:100% margin-bottom: 5px;}.statusAlign{width: 35%;float: right!important;margin: 10px 10px 0 0;}.lightFont{font-weight: lighter;margin-top: 5px;}' +
      '.DateRight{float: right;margin: 20px -5px 6px 0;}.inv-line {border-top: solid 2px darkgreen;}.inv-foot-detail {width: 100%;min-height: 200px;display: inline-block;padding-right:5px}.inv-notes{border: solid 1px #c9c9c9;max-height: 250px;min-height: 140px;\n' +
      '\tpadding: 10px;font-weight: lighter;overflow-y: auto;}.left-notes{border: solid 1px #c9c9c9;max-height: 168px;min-height: 168px;padding: 10px;font-weight: lighter;overflow-y: auto;}' +
      '.inv-payment-detail{width: 32%;float: right;display: inline-block;}.inv-payment-sub-detail{border: solid 2px #c9c9c9;}.left-panel{display: inline-block;width:calc(100% - 135px);border-bottom: solid 1px #c9c9c9;\n' +
      '\t\tpadding: 10px;font-weight: lighter;}.right-panel{display: inline-block;text-align: right;width: 96px;border-left: solid 1px #c9c9c9 !important;margin-left: -3px;text-align: right;padding: 10px;border-bottom: solid 1px #c9c9c9;\n' +
      '\t\tbackground-color: #f1f5f8;-webkit-print-color-adjust: exact;}.baldue{border-bottom: none;color: #fa5858}.baldueright{border: none;color: #fa5858}.themeColor{-webkit-print-color-adjust: exact;height: 60px;-webkit-print-color-adjust: exact;padding-bottom: 15px}' +
      '.footer{position: fixed;bottom: 0;width: 100%;height: 55px;background-color: #fff}.page{margin: 0 15px;font-size: 11px;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.bottomFooter{text-align: left; width: 33.33333333%; float: left;}.leftFooter{margin: 0 0 10px;}.rightFooter{text-align: right;width: 33.33333333%;float: left;}.centerFooter{width: 33.33333333%;float: left;}hr{width: 100%;}.notes{width:62%;padding-left: 15px;display: inline-block;}' +
      '.tableHead{font-family: "Open Sans", sans-serif;width: calc(100% - 30px);margin-left: 15px;box-shadow: none;font-weight: 600;border-spacing: 0px;border-top: 2px solid gray}.tableRow{height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left; padding-left: 15px; color: #000000de; font-size: 12px; font-weight: 400;}' +
      '.tableColumn{font-size:12px;border-bottom: 2px solid #939393; padding-left: 15px; text-align: left; color: #000000de;font-family: "Open Sans", sans-serif;height: 48px;font-weight: 400 !important;}.inv-item-table{padding: 5px;}' +
      '.nameAlign{display: inline-block}.clientAlign{display: inline-block;margin: 16px 0;}.clearfix{clear: both}.border{border: 2px solid black}.waterMark{text-align: center;position: relative;top: 85px;height: 0;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.styleWtareMarkRed{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: red;color: red;}' +
      '.mat-row:nth-child(even){  background-color:#f9f9f9; }.mat-row:nth-child(odd){ background-color:#fff; }' +
      '.item-desc-table{word-break: break-all}' +
      '.tableFirstRowCol{height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left;color: #000000de; font-size: 12px;font-family: "Open Sans", sans-serif; font-weight: 400;}' +
      '.f-r {float:right;}.successButton {color:rgb(0, 150, 60);border:1px solid rgb(0, 150, 60);}.dangerButton{color:rgb(200, 16, 16);border:1px solid rgb(200, 16, 16);}.invdate{padding: 10px 0px;padding-bottom: 3px;font-size: 14px}.warningButton {color: orange;border: 1px solid #f35d05;float: right;}.styleWtareMarkGreen{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: green;color: green;}' +
      '.styleWtareMarkOrange{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: orange;color: orange;}' +
      '.page-footer, .page-footer-space {height: 50px;.page-footer {position: fixed;bottom: 0;width: 100%;border-top: 1px solid black;background: yellow;}@media print {tfoot {display: table-footer-group;}button {display: none;} body {margin: 0;}}' +
      '</style><script>function myFunction() {window.close();}</script></head><body onafterprint="myFunction()" onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
    this.check = false;
  }

  // update item form based on setting tax update
  updateItemFormFields() {
    // updating column based on template type and tax visibility
    this.displayedColumns = this.configService.enableItemLevelTaxation === '1' ?
      (this.estimateDetList.templateType === templateType.default ? 
        ['item_name', 'item_desc', 'quantity', 'unit_price', 'tax_name', 'subTotal', 'tax'] : 
        (this.estimateDetList.templateType === templateType.summary ? 
          ['item_name', 'item_desc', 'quantity']: 
        ['item_name', 'item_desc', 'quantity', 'tax_name']))
      : (this.estimateDetList.templateType === templateType.default ?
        ['item_name', 'item_desc', 'quantity', 'unit_price', 'subTotal'] :
        ['item_name', 'item_desc', 'quantity']);

    this.groupTaxDisplayedColumns = this.estimateDetList.templateType === templateType.default ? 
      ['main_group_tax_name', 'sub_tax_name', 'subTotal', 'tax'] : 
      ['main_group_tax_name', 'sub_tax_name'];
  }

  getIPAddress() {
    this.agreementService.getIPAddress().subscribe((res:any)=>{
      this.ipAddr = res ? res.origin : '';
      console.log(this.ipAddr)
    });
  }
}
